import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"


const Hero = styled.div`
  height:100vh;
  /* background-color: black; */
  display: grid;
  grid-template-rows: 50px repeat(4,1fr);

  h1 {
    color: black;
    grid-row: 2;
    padding-top: 28px;
  }
`

const FormWrapper =styled.div`
    grid-row: 3;

    @media (max-width:960px)  { 
    grid-column: 1;
}

  form{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  input {
    font-size: 21px;
    border: solid 2px black;
    border-radius: 3px;
    padding:12px;
  }

  .field-wrapper{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  label {
    position: absolute;
    top: -2ch;
    left: 1ch;
    padding: 1ch;
    background-color: white;   
  }

  textarea {
    resize: none;
    height:200px;
    border: solid 2px black;
    border-radius: 3px;
    font-size: 21px;
    padding:12px;
  }

  button {
    border: solid 2px black;
    border-radius: 3px;
    font-size: 21px;
    padding: 12px;
    background-color: black;
    color: white;
    
  }

  button:hover {
    background-color: transparent;
    color: black;
    transition: .125s ease-in-out;
    cursor: pointer;
  }

`

const HeroInner = styled.div`
  grid-row:4;
  grid-gap:28px;
`

const ContactInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 3;

  @media (max-width:960px)  { 
    grid-column: 1;
    grid-row:5;
}

  .info-wrapper{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
  }
`

const Card = styled.div`
  padding: 28px;
  background-color: #f7f7f7;
  border-radius: 12px;
`

const ContactPage = () => {
    return (
        <div>
            <Layout>
            <SEO title="Contact"/>
                <Hero>
                    <h1 className="font-xl center">Let's bring your product to market.</h1>
                    <HeroInner className="col-2 md">
                      <Card>
                        <FormWrapper className="xs center">
                        <form                       
                            name="contact v1"
                            method="post" 
                            data-netlify="true" 
                            data-netlify-honeypot="bot-field"
                        >
                        <input type="hidden" name="form-name" value="contact v1" />
                        <div className="field-wrapper">
                            <label className="label-name" htmlFor="name">Name </label>
                            <input id="name" type="text" name="name" />
                        </div>
                        <div className="field-wrapper">
                            <label htmlFor="email">Email </label>
                            <input id="email" type="email" name="email" />
                        </div>
                        <div className="field-wrapper">
                            <label htmlFor="message">Message </label>
                            <textarea id="message" name="message" />
                        </div>
                        <div className="field-wrapper">
                            <button type="submit">Get in touch</button>
                        </div>
                        </form>
                        </FormWrapper>
                        </Card>
                        <Card>
                        <ContactInfo className="xs">
                          <h1>How does it work?</h1>
                          <div className="info-wrapper">
                          <div>
                          <h2>Step One</h2>
                          <p>Fill out the form with your name, email, and brief desctiption
                             of the services you're interested in and how we can assist you. </p>
                          </div>

                          <div>
                          <h2>Step Two</h2>
                          <p>A team member will respond via email, typically within the working day.</p>
                          </div>
                          
                          <div>
                          <h2>Step Three</h2>
                          <p>We'll set up a time to discuss your project needs and build a product development
                            plan and scope of work.
                          </p>
                          </div>
                          </div>
                        </ContactInfo>
                        </Card>
                    </HeroInner>
                </Hero>
            </Layout>
        </div>
    )
}

export default ContactPage